import React from "react";

import Layout from "../../../components/layout";
import SmallBanner from "../../../components/smallBanner";

const Delivery = () => {
  const lang = "en";

  return (
    <Layout
      seoTitle="Delivery"
      lang={lang}
      translationPL="/oferta/dostawa/"
      translationDE="/de/angebot/lieferung/"
    >
      <SmallBanner
        title="Delivery"
        aditionalBreadcrump="Our services"
        aditionalBreadcrumpLink="/en/our-services/"
      />
      <section className="text-section delivery-info  text-section--first">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main">
                Customised <br /> delivery plans
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-5 col-xl-4">
              <img
                className="delivery-info__left-image"
                src={require("../../../assets/img/delivery1.jpg")}
                alt="Dostawa"
              />
            </div>
            <div className="col-12 col-lg-7 col-xl-8">
              <div className="text-section__content">
                <p className="mb-30">
                  PalettenWerk guarantees fast and timely pallet deliveries
                  throughout Europe. Orders for standard pallets in Poland are
                  completed within 24 hours, and deliveries to other countries
                  are made within 3 working days of placing the order.
                </p>
                <p className="mb-60">
                  The organisation of production processes in PalettenWerk
                  allows us to fully adapt our production plans to the cycles
                  and frequency of customer orders. This allows us to carry out
                  individual delivery plans that are 100% tailored to the
                  customer’s needs.
                </p>
                <img
                  src={require("../../../assets/img/timeline2.jpg")}
                  alt="Dostawa"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="offer-delivery offer-delivery--background-white">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5 col-xl-5">
              <h2 className="small color--main">
                Loading method <br /> and load safety
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 offset-lg-5 offset-xl-4">
              <div className="offer-delivery__content">
                <p className="mb-20">
                  We transport our products using standard, mega, and double
                  trailer lorries, in which 756, 812, or 940 EPAL pallets can be
                  transported at one time. The method of pallet loading depends
                  on the customer’s preferences.
                </p>
                <p className="mb-60">
                  Our vehicles are equipped with a GPS tracking system. Each
                  load is covered by carrier’s liability insurance in national
                  and international road transport.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="delivery-map">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main">
                Delivery of pallets <br /> in Europe
              </h2>
            </div>
            <img
              className="delivery-map__image"
              src={require("../../../assets/img/delivery-map.png")}
              alt="Mapa Dostaw"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Delivery;
